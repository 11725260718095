<template>
  <div class="freight-query-div">
    <div class="freight-query">
      <!-- 主区域 -->
      <div class="main">
        <!-- 顶部背景 -->
        <div class="top-bg">
          <img width="100%" src="../assets/img/FreightQuery/top_bg.png" />
        </div>
        <!-- 订单输入 -->
        <div class="order-input-div">
          <!-- 标题 -->
          <div class="title-text">运费与报价</div>
          <!-- 内容输入 -->
          <div class="order-input">
            <!-- 发出地 -->
            <div class="where-from">从英国发往</div>
            <!-- 发往地 -->
            <el-select class="where-select" v-model="orderData.whereReceive"  placeholder="请选择" ref="addressSelect" popper-class="my-select">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!-- 输入的数据 -->
            <div class="data-input">
              <!-- 重量 -->
              <el-tooltip popper-class="my-tooltip" :manual=true :value="orderCheck.weight == false" content="请输入此项" placement="top">
                <el-input v-model="orderData.weight" placeholder="重量" @input="limitInput($event,'weight')" @change='orderCheck.weight = true'></el-input>
              </el-tooltip>
              <div class="unit">KG</div>
              <!-- 长度 -->
              <el-tooltip popper-class="my-tooltip" :manual=true :value="orderCheck.length == false" content="请输入此项" placement="top">
                <el-input v-model="orderData.length" placeholder="长度" @input="limitInput($event,'length')" @change='orderCheck.length = true'></el-input>
              </el-tooltip>
              <div class="unit">CM</div>
              <!-- 宽度 -->
              <el-tooltip popper-class="my-tooltip" :manual=true :value="orderCheck.width == false" content="请输入此项" placement="top">
                <el-input v-model="orderData.width" placeholder="宽度" @input="limitInput($event,'width')" @change='orderCheck.width = true'></el-input>
              </el-tooltip>
              <div class="unit">CM</div>
              <!-- 高度 -->
              <el-tooltip popper-class="my-tooltip" :manual=true :value="orderCheck.height == false" content="请输入此项" placement="top">
                <el-input v-model="orderData.height" placeholder="高度" @input="limitInput($event,'height')" @change='orderCheck.height = true'></el-input>
              </el-tooltip>
              <div class="unit">CM</div>
            </div>
            <!-- 运费估算 -->
            <div class="order-btn freightMath posi" @click="onFreightQueryClick">运费估算</div>
            <!-- 立即下单 -->
            <div class="order-btn orderNow posi" @click="onOrderNowClick">立即下单</div>
          </div>
        </div>
        <!-- 搜索结果 -->
        <div class="seach-result-div">
          <!-- 标题 -->
          <div class="title-text">搜索结果</div>
          <!-- 结果展示 -->
          <div class="seach-show table-div">
            <el-table
              :data="seachShowData"
              style="width: 100%"
              border
              empty-text="暂无搜索结果"
              >
              <el-table-column
                prop="destination"
                label="目的地"
                width="94"
                :resizable="false">
              </el-table-column>
              <el-table-column
                prop="serviceName"
                label="服务线路"
                width="194"
                :resizable="false">
              </el-table-column>
              <el-table-column
                prop='cost'
                label="运费"
                width="95"
                :resizable="false">
              </el-table-column>
              <el-table-column
                prop="timeliness"
                label="运送时效"
                width="116"
                :resizable="false">
              </el-table-column>
              <el-table-column
                prop="note"
                label="线路特点"
                :resizable="false">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 运费标价表 -->
        <div class="freight-offer-div">
          <!-- 标题 -->
          <div class="offet-title">
            <div class="title-text">运费报价表</div>
          </div>
          <!-- 结果展示 -->
          <div class="offer-show table-div">
            <el-table :data="freightShowData" style="width: 100%" border>
              <el-table-column v-for="(item,index) in freightShowTitle" :key="index" :label="item" :resizable="false">
                <template slot-scope="scope">
                  <span v-html="scope.row[index]">{{scope.row[index]}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { costEstimateInfo, getServiceLinesCostList } from "@/api/FreightQuery/FreightQuery"
export default {
  name:'FreightQuery',
  data(){
    return{
      //可选择的国家
      options: [
        {
          value: 'CN',
          label: 'CN 中国大陆'
        }
      ],
      // 订单信息
      orderData: {
        //接收地
        whereReceive: "CN",
        //重量
        weight: "",
        //长度
        length: "",
        //宽度
        width: "",
        //高度
        height: "",
      },
      //检测订单输入是否合规
      orderCheck:{
         //重量
        weight: true,
        //长度
        length: true,
        //宽度
        width: true,
        //高度
        height: true,
      },
      //搜索结果数据
      seachShowData:[],
      // 运费报价表数据
      freightShowData: [],
      // 运费报价表标头
      freightShowTitle:[]
    }
  },
  created(){
    this.getServiceLinesCostList();
  },
  methods:{
    limitInput(value, name) {
      this.orderData[name] =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^\\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    },
    // 运费估算被点击
    onFreightQueryClick(){
      if(!this.cheackOrderInput()) return;
      console.log("进行估算");
      this.costEstimateInfo();
    },
     // 立即下单被点击
    onOrderNowClick(){
      if(!this.cheackOrderInput()) return;
      console.log("进行下单");
      // 存储目的地
      let mySelect = this.$refs.addressSelect.selectedLabel;
      // 存储目的地
      this.$store.dispatch('updateDest', mySelect);
      this.$store.dispatch('updateItemInfo', this.orderData);
      this.$store.state.step = 1
      this.$router.push({
        path: `/OrderNow`,
      })
    },
    // 运费查询
    costEstimateInfo(){
      let upData = {
        height: this.orderData.height,
        length: this.orderData.length,
        weight: this.orderData.weight,
        width: this.orderData.width
      }
      costEstimateInfo(upData).then(res => {
        if(res.data.code === 0) {
          this.seachShowData = res.data.data.list;
        }
        console.log(res, '返回值')
      })
    },
     // 获取运费报价表
    getServiceLinesCostList(){
      getServiceLinesCostList().then(res => {
        if(res.data.code === 0) {
          this.freightShowTitle = res.data.data.title;
          this.freightShowData = res.data.data.data;
        }
        console.log(res, '获取运费报价表接口返回值')
      })
    },
    //检测订单输入
    cheackOrderInput(){
      if(!this.orderData.weight){
        this.orderCheck.weight = false;
        return false;
      }
      if(!this.orderData.length){
        this.orderCheck.length = false;
        return false;
      }
      if(!this.orderData.width){
        this.orderCheck.width = false;
        return false;
      }
      if(!this.orderData.height){
        this.orderCheck.height = false;
        return false;
      }
      return true;
    },
    //订单输入进行了修改
    // inputOninput(arg){
    //   if(arg == "weight"){
    //     this.orderCheck.weight = true;
    //   }
    //   else if(arg == "length"){
    //     this.orderCheck.length = true;
    //   }
    //   else if(arg == "width"){
    //     this.orderCheck.width = true;
    //   }
    //   else if(arg == "height"){
    //     this.orderCheck.height = true;
    //   }
    // },
  }
}
</script>
<style>
/* tooltip 自定义样式 */
.my-tooltip.is-dark{
  background: #FF6400;
}
.my-tooltip[x-placement^=top] .popper__arrow::after{
  border-top-color: #FF6400;
}
.my-tooltip[x-placement^=top] .popper__arrow{
  border-top-color: #FF6400;
}
/* 修改table样式 */
.seach-show .el-table__empty-text{/* 无数据时 */
  display: block;
  height: 172px;
  background: url("../assets/img/not_data_icon.png") no-repeat;
  background-size: 67px 50px;
  background-position: 50% 41px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  color: #636D88;
  line-height: 240px;
}
/* 搜索结果对最后一列特殊操作 */
.seach-show .el-table__row > td:last-child .cell{
  text-align: left;
}
</style>
<style lang="less" scoped>
.freight-query-div{
  .freight-query{
    position: relative;
    background: #f4f4f4;
    padding-top: 39px;
    // 主区域
    .main{
      width: 100%;
      padding-bottom: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // 顶部背景
      .top-bg{
        width: 100%;
        position: absolute;
        top: 0px;
      }
      //运费与报价
      .order-input-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        // title
        .title-text{
          height: 33px;
          font-size: 24px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 33px;
        }
        // 订单内容输入
        .order-input{
          height: 96px;
          width: 1200px;
          background: #FFFFFF;
          border-radius: 8px;
          margin-top: 45px;
          display: flex;
          flex-direction: row;
          align-items: center;
          // el-select样式
          .where-select::v-deep .el-input .el-input__inner{
            width: 160px;
            height: 36px;
            margin-left: 24px;
          }
          .where-select::v-deep .el-input .el-select__caret{
            line-height: 18px;
          }
          // el-input样式
          .data-input::v-deep .el-input .el-input__inner{
            width: 90px;
            height: 36px;
            margin-left: 12px;
          }
          // 发出地
          .where-from{
            height: 20px;
            font-size: 14px;
            font-weight: bold;
            color: #1E2B4F;
            line-height: 20px;
            margin-left: 30px;
          }
          // 输入的数据
          .data-input{
            display: flex;
            flex-direction: row;
            align-items: center;
            // 单位
            .unit{
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #1E2B4F;
              line-height: 20px;
              margin-left: 6px;
            }
          }
          //订单按钮公共样式
          .order-btn{
            width: 80px;
            height: 36px;
            background: #6D5EFE;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 36px;
            text-align: center;
          }
          // 运费估算
          .freightMath{
            margin-left: 40px;
          }
          // 立即下单
          .orderNow{
            margin-left: 31px;
          }
        }
      }
      // 搜素结果
      .seach-result-div{
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        z-index: 1;
        // title
        .title-text{
          height: 28px;
          font-size: 20px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 28px;
        }
        //搜索结果展示
        .seach-show{
          background:white;
          margin-top: 16px;
          width: 1200px;
          border-radius: 8px;
          overflow: hidden;
        }
      }
      // 运费报价表
      .freight-offer-div{
        display: flex;
        flex-direction: column;
        margin-top: 41px;
        z-index: 1;
        // title
        .offet-title{
          display: flex;
          flex-direction: row;
          align-items: center;
          .title-text{
            height: 28px;
            font-size: 20px;
            font-weight: bold;
            color: #1E2B4F;
            line-height: 28px;
          }
        }
        //报价展示
        .offer-show{
          background:white;
          margin-top: 16px;
          width: 1200px;
          border-radius: 8px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>