import http from "@/utils/http";

const resquest = `${process.env.VUE_APP_BASE_URL}`;
// const resquest = `http://192.168.1.100:7000/express`

// 运费估算
export function costEstimateInfo(data) {
  return http.post(`${resquest}/user/customerPricePlan/costEstimateInfo`, data);
}
//获取运费报价表
export function getServiceLinesCostList() {
  return http.get(`${resquest}/user/customerPricePlan/getServiceLinesCostList`);
}